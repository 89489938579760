@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-bgColor dark:bg-darkModeBgColor dark:text-darkModeText font-sans transition-colors;
  }
  h1 {
    @apply font-display;
  }
}

@layer components {
  /* section titles and its decorating span block*/
  .section-title {
    @apply mt-24;
  }

  .section-title__span {
    @apply before:block before:absolute before:top-8 before:right-5 before:left-0 before:-bottom-1 before:bg-pink-500 dark:before:bg-teal-400 dark:before:bg-gradient-to-r dark:before:from-blue-500 dark:before:via-sky-400 dark:before:to-teal-400 relative inline-block mb-12;
  }

  .section-title__h2 {
    @apply font-semibold text-2xl relative;
  }

  /* skills icons' style */
  .skills-icons-size {
    @apply w-20 h-20;
  }
  .skills-icons-container {
    @apply flex flex-col items-center gap-y-5 tracking-wide font-medium;
  }
  .sidebar--linkedin-svg {
    @apply group-hover:fill-bgColor dark:fill-darkModeText transition duration-150 ease-in-out;
  }

  /* modal's class - hamburger menu */
  .modal {
    @apply w-screen h-screen top-0 left-0 right-0 bottom-0 fixed z-40;
  }
  .modal--content {
    @apply absolute rounded-[3px] text-center z-50;
  }
  .active-modal {
    @apply overflow-y-hidden;
  }

  /* contact form style */
  .contact__input {
    @apply border-2 dark:bg-darkInputBg border-black dark:border-bgColor rounded-sm focus:outline focus:outline-offset-[3px] focus:outline-2 focus:outline-black dark:focus:outline-bgColor p-3;
  }
  .contact__errorMessage {
    @apply absolute text-sm text-rose-800 dark:text-rose-400;
  }

  /* Menu language dropdown */
  .language__dropdown--elements {
    @apply w-24 py-1 cursor-pointer bg-stone-200 hover:bg-stone-400 dark:bg-gray-900 dark:hover:bg-gray-500;
  }
}

/* Projects hover effect */
.project-container:hover .project-image {
  opacity: 1;
}

.project-container:hover .project-details {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1.5px);
}

.project-container:hover .project-details__text {
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
